<template>
  <div>
    <ModuleForm
      :module-name="$tc('moduleForm.hottopics')"
      :show-header-info="disabled"
    >
      <template #form-title>
        {{ $t(`route.${$route.name}`) }}
      </template>
      <template #header-buttons>
        <ModuleFormButton
          v-if="isEditMode"
          :title="$tc('buttons.unpublish')"
          @click="unpublish"
        />
        <ModuleFormButton
          v-if="disabled"
          :required-permissions="requiredPermissions.editButton"
          icon="edit"
          @click="goToEdit"
        />
        <ModuleFormButton
          v-else
          :title="$tc('buttons.save')"
          :required-permissions="requiredPermissions.saveButton"
          @click="save"
        />
        <ModuleFormDeleteButton
          v-if="hottopics.id"
          :record-id="hottopics.id"
          :required-permissions="requiredPermissions.deleteButton"
          store-name="hottopics"
          list-router-path="hottopics"
        />
        <ModuleFormButton
          icon="close"
          @click="$router.push('/hottopics')"
        />
      </template>
      <template #left-column>
        <div class="title-text">
          {{ $tc('hottopics.image_label') }}
        </div>
        <ModuleFormPhotoBox
          :image="hottopicImage"
          :disabled="disabled"
          :media-usage-type="mediaUsageType"
          @set-media="setImage"
          @remove-media="removeImage"
        />
      </template>
      <template #right-column>
        <Input
          v-model="hottopics.text"
          @blur="$v.hottopics.text.$touch()"
          :error="$v.hottopics.text.$error"
          id="hottopics_text"
          :label="$tc('hottopics.text')"
          :placeholder="$tc('hottopics.text_placeholder')"
          :disabled="disabled"
          :maxAvailableLength="65"
          show-counter
          show-max-length
          required
        />
        <Input
          v-model="hottopics.linkUrl"
          @blur="$v.hottopics.linkUrl.$touch()"
          :error="$v.hottopics.linkUrl.$error"
          id="hottopics_linkUrl"
          :label="$tc('hottopics.link')"
          :placeholder="$tc('hottopics.link_placeholder')"
          :disabled="disabled"
          input-type="url"
          required
        />
        <div class="row">
          <div class="col-md-6">
            <Select
              v-model="hottopics.position"
              :options="panelPositions"
              id="hottopics_panelPositions"
              :label="$tc('hottopics.panel_positions')"
              no-empty-value
              :disabled="disabled"
            />
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-6">
                <label class="input-area__label" for="hottopics_createdAt">
                  {{ $tc('hottopics.created_date') }}
                </label>
                <Datepicker
                  v-model="hottopics.createdAt"
                  id="hottopics_createdAt"
                  no-label
                  disable-form-group
                  :disabled="true"
                >
                </Datepicker>
              </div>
              <div class="col-6">
                <label class="input-area__label" for="hottopics_modifiedAt">
                  {{ $tc('hottopics.changed_date') }}
                </label>
                <Datepicker
                  v-model="hottopics.modifiedAt"
                  id="hottopics_modifiedAt"
                  no-label
                  disable-form-group
                  :disabled="true"
                >
                </Datepicker>
              </div>
            </div>
          </div>
        </div>
        <div class="hottopics-multiselect">
          <div class="hottopics-multiselect__multiselect">
            <MultiSelect
              v-model="sitesSelectValue"
              :options="[]"
              :internal-search="false"
              :show-no-results="false"
              :searchable="false"
              :disable-opening="true"
              label="title"
              track-by="id"
              id="hottopics_siteIds"
              :label-desc="$tc('modal.journalsselection.sites_label_extended')"
              :error="$v.sitesSelectValue.$error"
              :disabled="isEditMode"
              required
            />
          </div>
          <button
            class="hottopics-multiselect__btn"
            @click="$refs.journalsSelectionModal.open()"
            :disabled="isEditMode"
          >{{ $tc('modal.journalsselection.sites_button_label') }}</button>
        </div>
      </template>
    </ModuleForm>
    <app-journals-selection-modal
      ref="journalsSelectionModal"
      @onSelectedSites="handleSelectedSites"
      :enabled-sites="enabledSites"
      :regions="regions"
      :defaultSelectedSiteIds="hottopics.siteIds"
      :permissionModule="permissionModule"
    ></app-journals-selection-modal>
  </div>
</template>

<script>
import { isURL } from '@/filters'
import { maxLength, minLength, required, helpers } from 'vuelidate/lib/validators'
import Input from '@/components/form/inputs/Input'
import Select from '@/components/form/select/Select'
import MultiSelect from '@/components/form/select/MultiSelect'
import HottopicsModel from '@/model/HottopicsModel'
import NotifyService from '@/services/NotifyService'
import Datepicker from '@/components/form/Datepicker'
import ModuleForm from '@/components/shared/ModuleForm'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import ModuleFormDeleteButton from '@/components/shared/ModuleFormDeleteButton'
import ModuleFormPhotoBox from '@/components/shared/ModuleFormPhotoBox'
import PermissionService from '@/services/PermissionService'
import JournalsSelectionModal from '@/components/shared/JournalsSelectionModal'
import { mapGetters } from 'vuex'
import { MEDIA_USAGE_TYPE_HOT_TOPIC } from '@/model/ValueObject/MediaUsageTypes'
import HottopicsPanelPositions from '@/model/ValueObject/HottopicsPanelPositions'
import { MODULE_MODULES_HOT_TOPICS } from '@/model/ValueObject/UserPermissionModules'

export default {
  name: 'HotTopicsNewView',
  data () {
    return {
      hottopics: this._.cloneDeep(HottopicsModel),
      panelPositions: HottopicsPanelPositions,
      permissionModule: MODULE_MODULES_HOT_TOPICS,
      isEditMode: false,
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.HOT_TOPICS_PERMISSIONS,
      mediaUsageType: MEDIA_USAGE_TYPE_HOT_TOPIC
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  validations () {
    return {
      hottopics: {
        text: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(65)
        },
        linkUrl: {
          required,
          isURL: helpers.withParams({ type: 'url' }, value => !value || isURL(value))
        }
      },
      sitesSelectValue: {
        required
      }
    }
  },
  components: {
    ModuleFormPhotoBox,
    ModuleFormDeleteButton,
    ModuleFormButton,
    Input,
    Select,
    MultiSelect,
    Datepicker,
    ModuleForm,
    appJournalsSelectionModal: JournalsSelectionModal
  },
  computed: {
    ...mapGetters(['vlm']),
    hottopicImage () {
      return this.$store.getters['hottopics/hottopicImage']
    },
    board () {
      return this.$store.getters['hottopics/board']
    },
    allEnabledSorted () {
      return this.$store.getters['site/allEnabledSorted']()
    },
    sitesSelectValue: {
      get () {
        if (this.hottopics.siteId && this.allEnabledSorted.length) {
          return this.allEnabledSorted.find(site => site.id === this.hottopics.siteId) || []
        }

        return this.hottopics.siteIds
          ? this.hottopics.siteIds.map(siteId => this.allEnabledSorted.find(site => site.id === siteId))
          : []
      },
      set (value) {
        if (value) {
          this.hottopics.siteIds = value.map(site => site.id)
        } else {
          this.hottopics.siteIds = []
        }
      }
    },
    enabledSites () {
      return this.$store.getters['site/enabledSites'](this.permissionModule)
    },
    regions () {
      return this.$store.getters['site/regions']
    }
  },
  methods: {
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.hottopics.imageId = null
      if (this.$store.getters['hottopics/hottopicImage']) {
        this.hottopics.imageId = this.$store.getters['hottopics/hottopicImage'].id
      }
      this.$store.dispatch('hottopics/create', {
        position: this.hottopics.position,
        text: this.hottopics.text,
        imageId: this.hottopics.imageId,
        linkUrl: this.hottopics.linkUrl,
        siteIds: this.hottopics.siteIds
      })
        .then(() => {
          if (this.$store.getters['hottopics/error'] === null) {
            this.goToLastView()
            NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['hottopics/error'])
          }
        })
        .catch(error => console.error(error))
    },
    setImage (medias) {
      medias.forEach(media => {
        this.$store.commit('hottopics/storeHottopicImage', media)
      })
    },
    removeImage () {
      this.$store.commit('hottopics/storeHottopicImage', null)
    },
    handleSelectedSites (sites) {
      this.hottopics.siteIds = sites.map(site => site.id)
    },
    goToEdit () {
      this.$router.push('/hottopics/' + this.hottopics.id + '/edit')
    },
    goToLastView () {
      this.$router.push('/hottopics')
    },
    unpublish () {
      this.$store.dispatch('hottopics/unpublish', this.hottopics.id)
        .then(() => {
          this.goToLastView()
          NotifyService.setSuccessMessage(this.$t('notify.record_was_unpublished'))
        })
        .catch(error => {
          NotifyService.setErrorMessage(error)
        })
    }
  },
  created () {
    this.$store.commit('hottopics/storeHottopicImage', null)
  },
  async mounted () {
    await this.$store.dispatch('site/fetchRegions')
  }
}
</script>
<style scoped lang="scss">
.hottopics-multiselect {
  display: flex;
  justify-content: space-between;

  &__multiselect {
    flex: 1 1 0;
  }
  &__btn {
    flex: 0 0 auto;
    padding-left: rem(25px);
    padding-right: rem(25px);
    font-family: "Roboto", sans-serif;
    font-size: rem(13px);
    font-weight: 500;
    color: #fff;
    background: #6599FE;
    border: none;
    border-radius: 0 rem(6px) rem(6px) 0;
    cursor: pointer;
    height: rem(43px);
    transition: background 100ms;
    display: flex;
    align-items: center;
    align-self: end;
    &:hover,
    &:focus {
      background: darken(#6599FE, 10%);
    }
    &:disabled {
      background: #6599FE;
      opacity: 0.7;
    }
  }
}
.form-content {
  &__left {
    ::v-deep & {
      flex: 0 0 32%;
    }
  }
  &__right {
    ::v-deep & {
      flex: 1 1 0;
    }
  }
}
</style>
